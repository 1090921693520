input[type="text"],
input[type="number"],
input[type="email"],
input[type="password"],
textarea {
  font-size: 16px !important;
}

.fixed-footer {
  position: fixed;
  width: 100vw;
  bottom: 0;
  left: 15px;
  z-index: 1;
  box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);

  @media (min-width: 992px) {
    display: none;
  }
}

.dynamic-cart-padding-bottom,
.dynamic-padding-bottom {
  padding-block-end: calc(1rem + env(safe-area-inset-bottom) / 2);

  /* apply styles only for PWA version */
  @media (display-mode: standalone) {
    padding-block-end: calc(2rem + env(safe-area-inset-bottom));
  }
}

.scrollable-table-responsive {
  height: calc(100dvh - 280px);
  overflow: scroll;

  thead tr:nth-child(1) th {
    background: white;
    position: sticky;
    top: -1px;
    z-index: 10;
  }
}

.hoverable {
  &:hover {
    cursor: pointer;
  }
}

.scrollbar-hidden {
  &::-webkit-scrollbar {
    display: none;
    background-color: #fff;
    width: 16px;
  }

  /* background of the scrollbar except button or resizer */
  &::-webkit-scrollbar-track {
    background-color: #fff;
  }

  /* scrollbar itself */
  &::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #fff;
  }

  /* set button(top and bottom of the scrollbar) */
  &::-webkit-scrollbar-button {
    display: none;
  }
}

.scrollable-y,
.card-body,
.modal-content {
  &::-webkit-scrollbar {
    display: none;
    background-color: #fff;
    width: 16px;
  }

  /* background of the scrollbar except button or resizer */
  &::-webkit-scrollbar-track {
    background-color: #fff;
  }

  /* scrollbar itself */
  &::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #fff;
  }

  /* set button(top and bottom of the scrollbar) */
  &::-webkit-scrollbar-button {
    display: none;
  }
}
.media-manager-container {
  max-height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;

  img {
    max-width: 130px !important;
    height: auto;

    @media (min-width: 767px) {
      max-width: 180px !important;
    }
  }
}

.media-manager-btn-container {
  gap: 10px;

  button {
    margin-left: 0 !important;
  }
}

.image-preview {
  object-fit: cover;
  display: block;
  position: relative;
  height: 70px;
  max-width: 70px;

  @media (min-width: 767px) {
    margin-left: 0;
    height: 70px;
    max-width: 70px;
  }
}

.new-order-products-view {
  .product-name {
    line-height: 21px;

    @media (min-width: 767px) {
      font-size: 15px;
    }
  }
  .card {
    .card-body {
      @media (min-width: 767px) {
        min-height: 80px;
      }
    }
  }

  @media (max-width: 767px) {
    & + .container-fluid {
      display: none;
      footer {
        display: none;
      }
    }
  }

  .image-container {
    width: 70px;
    min-height: 70px;
    background: #f2f2f2;
    border-radius: 10px;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='12' cy='12' r='9' stroke='black' stroke-width='6'/%3E%3Ccircle cx='12' cy='12' r='4.15385' fill='%234246FD'/%3E%3C/svg%3E%0A");
    opacity: 0.6;
    background-repeat: no-repeat;
    background-position: center;

    &.has-image {
      opacity: 1;
    }
  }
}

.category-card-container,
.subcategory-card-container {
  min-height: 70px;

  @media (min-width: 992px) {
    height: auto;
    min-height: 110px;
  }
}

.table-text-name {
  min-height: 70px;
}

.responsive-table {
  &.table.align-items-center td,
  &.table.align-items-center th {
    white-space: normal;
    @media (max-width: 767px) {
      text-align: center;
    }
  }

  @media (max-width: 767px) {
    &.table td,
    &.table th {
      padding-left: 0.7rem;
      padding-right: 0.7rem;
    }
  }
}

.orders-view {
  .card-wrapper {
    @media (max-width: 767px) {
      padding: 0 10px;

      .card-body {
        padding: 0.5rem 0.5rem;
      }
    }
  }
}

.new-order-products-view {
  .card-wrapper {
    @media (max-width: 767px) {
      padding: 0 5px;

      .card-body {
        padding: 0.5rem 1rem;
      }
    }
  }
}

.cart-sidebar-enabled {
  .card-wrapper {
    height: calc(100dvh - 170px);

    @media (min-width: 767px) {
      height: calc(100vh - 170px);
    }

    .card-body {
      height: 100%;
      overflow-y: auto;
    }
  }
}

.icon {
  @media (max-width: 767px) {
    width: 1.5rem;
    height: 1.5rem;

    i {
      font-size: 0.75rem;
    }
  }
}

.login-logo {
  height: 70px;
}

.tables-view-header {
  padding-bottom: 0;

  .nav-tabs {
    border-bottom: 0;
  }
}

.card-header {
  .card-header-back-button {
    display: flex;
    align-items: center;
    .back-button {
      &:hover {
        cursor: pointer;
      }
      &::before {
        content: "";
        width: 11px;
        height: 15px;
        display: inline-block;
        margin-right: 10px;
        background-image: url("data:image/svg+xml,%3Csvg width='11' height='15' viewBox='0 0 11 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.18604 6.83789C0.827962 7.2041 0.827962 7.79883 1.18604 8.16504L6.68603 13.79C7.04411 14.1563 7.62562 14.1563 7.98369 13.79C8.34176 13.4238 8.34176 12.8291 7.98369 12.4629L3.13109 7.5L7.98083 2.53711C8.3389 2.1709 8.3389 1.57617 7.98083 1.20996C7.62275 0.84375 7.04124 0.84375 6.68317 1.20996L1.18317 6.83496L1.18604 6.83789Z' fill='black'/%3E%3C/svg%3E%0A");
      }
    }
  }
}

.cart-container {
  position: relative;

  .scollable-panel {
    height: calc(100dvh - 170px);
    overflow-y: auto;

    .cart-header {
      top: 19px;
      z-index: 1;

      .close-btn {
        top: 0;
      }
    }
  }

  &.invoice-selection-enabled {
    .scollable-panel {
      height: calc(100dvh - 220px);
    }
  }

  .close-btn {
    position: absolute;
    left: 12px;
    top: -2px;
    font-size: 22px;

    &::before {
      width: 19px;
      height: 19px;
      content: "";
      display: inline-block;
      background-image: url("data:image/svg+xml,%3Csvg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.7264 2.60693L2.60693 3.7264L8.38053 9.5L2.60693 15.2736L3.7264 16.3931L9.5 10.6195L15.2736 16.3931L16.3931 15.2736L10.6195 9.5L16.3931 3.7264L15.2736 2.60693L9.5 8.38053L3.7264 2.60693Z' fill='black'/%3E%3C/svg%3E%0A");
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.product-variants,
.product-notes {
  font-size: 12px;

  @media (min-width: 767px) {
    font-size: 14px;
  }
}

.product-quantity-btn-circle {
  min-width: 38px;
  min-height: 38px;

  &:nth-child(2n + 1) {
    margin-left: 0.5rem;
  }
  &:first-child {
    margin-right: 0.5rem;
    margin-left: 0;
  }
}

.quantity-container {
  .quantity {
    font-size: 11px;
  }

  @media (min-width: 767px) {
    bottom: 10px;
  }
}

.price {
  font-size: 14px;
}

.transfer-items-view {
  .scrollable-table-responsive {
    height: calc(100dvh - 380px);
  }
}

.cancel-items-view {
  .scrollable-table-responsive {
    height: calc(100dvh - 350px);
  }
}

.pay-items-view,
.transfer-items-view {
  .form-check {
    #checkAll {
      top: -10px;
    }
  }

  .custom-checkbox .custom-control-label::before {
    width: 1.2rem;
    height: 1.2rem;
    border: 1px solid #d1d1d1;
  }

  @media (max-width: 767px) {
    .card-body {
      padding: 5px;

      td,
      th {
        padding: 10px 5px;
      }
    }
  }

  .total-price {
    span {
      font-size: 18px;
    }
  }
}

table {
  .hoverable-table-row {
    &:hover {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      cursor: pointer;
    }
  }
}
.tables-view {
  .customer-table {
    span {
      box-shadow: rgb(0 0 0 / 10%) 0px 0px 0px 1px, rgb(0 0 0 / 10%) 0px 4px 16px;
    }
  }
}

.modal-content {
  overflow-y: auto;
  max-height: 80vh;

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
    max-height: 200px;
  }
  .close {
    z-index: 1;
    background: rgb(255, 255, 255);
    border-radius: 50%;
    color: rgb(155, 155, 155);
    background-repeat: no-repeat;
    background-position: center;
    width: 28px;
    height: 28px;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_3775_4171)'%3E%3Cpath d='M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z' fill='black'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_3775_4171'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
    // background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512' class='' width='16' height='16'%3E%3Cpath fill='currentColor' d='M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z'%3E%3C/path%3E%3C/svg%3E");
  }

  .product-footer {
    z-index: 1;
  }
}

.filter-icon {
  &::before {
    content: "";
    display: inline-block;
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_2804_4205)'%3E%3Cpath d='M5 9H7V8H5V9ZM1.5 3V4H10.5V3H1.5ZM3 6.5H9V5.5H3V6.5Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_2804_4205'%3E%3Crect width='12' height='12' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
    width: 12px;
    height: 10px;
    margin-right: 5px;
    margin-top: 1px;
    background-repeat: no-repeat;
  }
}

.filter-tags {
  &.desktop {
    display: none;
    @media (min-width: 767px) {
      display: flex;
      flex-wrap: wrap;
      justify-content: end;
      gap: 10px;
    }
  }

  &.mobile {
    display: flex;
    margin-top: 10px;
    @media (min-width: 767px) {
      display: none;
    }
  }
  .filter-tag {
    background-color: #e8e8e8;
    border-radius: 4px;
    font-size: 14px;
    margin-right: 10px;
    padding: 5px 10px;
    display: flex;
    align-items: center;

    @media (max-width: 767px) {
      margin-top: 5px;
    }

    .text {
      border-right: 1px solid #dcdcdc;
      padding-right: 10px;
    }

    .remove {
      display: inline-block;
      content: "";
      background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.74577 1.9209L1.9209 2.74577L6.17513 7L1.9209 11.2542L2.74577 12.0791L7 7.82487L11.2542 12.0791L12.0791 11.2542L7.82487 7L12.0791 2.74577L11.2542 1.9209L7 6.17513L2.74577 1.9209Z' fill='black'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: center;
      width: 14px;
      height: 14px;
      border: none;
      margin-left: 10px;
      margin-top: 1px;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.view-orders-view,
.edit-printers-view {
  .order-info {
    font-size: 0.875rem;
    color: rgb(155, 155, 155);
  }
  .form-control-label {
    color: #525f7f;
    font-weight: 700;
    font-size: 0.75rem;
    margin-bottom: 0;
    line-height: normal;
  }

  .form-control-value {
    margin-bottom: 0px;
    margin-top: 0;
    font-size: 0.875rem;
    line-height: normal;
  }

  .vieworder-comments {
    p {
      margin-bottom: 0;
    }
  }
}

.edit-printers-view {
  .printer-groups-accordion {
    .group-categories-table {
      th {
        border: unset;
      }

      tr {
        &:hover {
          cursor: pointer;
        }
      }
      th,
      td {
        padding: 1rem 0.5rem;
      }
    }

    .delete-group-btn {
      font-size: 14px;
      &:hover {
        cursor: pointer;
        color: #d40b33 !important;
      }
    }
  }
}

.btn-outline-default {
  color: grey;
  border: 1px solid grey;

  &:hover {
    color: grey;
    border: 1px solid grey;
    background-color: white;
    box-shadow: none;
  }
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  border-color: #dee2e6 #dee2e6 white;
  background-color: unset;
}

#language-selector {
  padding: 0;

  & > button {
    border: none;
    padding: 0;
  }
  ul {
    li {
      padding: 2px 15px;
    }
  }
}

.permissions-accordion {
  .disabled {
    &::after,
    h5 {
      color: #c0c0c0;
    }
  }
}

.capitalize {
  text-transform: capitalize;
}

textarea#description {
  height: 170px;
}

.custom-control {
  &.quantity-support {
    .number {
      position: absolute;
      left: -21px;
      color: $white;
      font-weight: 500;
    }

    .quatity-btn-group {
      display: flex;
      justify-content: right;
      position: absolute;
      right: -2px;
      top: -5px;
      z-index: 100;
      background: white;
      width: 115px;

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
        line-height: 1.5rem;
        font-size: 1.3rem;
        font-weight: 400;
        margin-right: 2px;
        margin-left: 0;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .custom-control-input {
    z-index: 5;
    &:hover {
      cursor: pointer;
    }
  }

  .custom-control-label {
    &::after {
      display: none !important;
    }

    &.large {
      height: auto !important;

      &::before {
        height: 22px;
        width: 22px;
        top: 0;
      }

      .row {
        margin-left: -13px;

        .label-text {
          line-height: 22px;
        }
      }
    }
  }
}

.cart-button {
  border-radius: 5px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.cart-sidebar-desktop {
  .card-header {
    min-height: 62px;
  }

  .discount-dropdown-card {
    .card-header {
      min-height: unset;
    }
  }
}

.hint {
  font-size: 12px;
  color: rgb(122, 130, 145);
}

.payment-method-dialog {
  .custom-radio {
    &:last-of-type {
      .payment-method-option {
        border-bottom: unset;
      }
    }
    .payment-method-option {
      display: flex;
      justify-content: space-between;
      font-size: 1rem !important;
      border: none;
      background: transparent;
      border-bottom: 1px solid #eeeeee;
      padding: 16px 10px;
      text-align: left;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 15px;
      font-weight: 600;
      padding-bottom: 27px;
      padding-top: 27px;
      color: rgb(155, 155, 155, 0.7);

      &:hover {
        cursor: pointer;
      }

      &::before {
        top: 30px;
      }

      &.active {
        color: black;
      }

      .icon {
        content: "";
        display: inline-block;
        width: 40px;
        height: 26px;
        background-repeat: no-repeat;
        background-position: 50%;
      }

      &.method-cash {
        .icon {
          background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 640 512' class='' width='26px' height='100%25'%3E%3Cpath fill='currentColor' d='M608 64H32C14.33 64 0 78.33 0 96v320c0 17.67 14.33 32 32 32h576c17.67 0 32-14.33 32-32V96c0-17.67-14.33-32-32-32zm-16 272c-35.35 0-64 28.65-64 64H112c0-35.35-28.65-64-64-64V176c35.35 0 64-28.65 64-64h416c0 35.35 28.65 64 64 64v160zM320 160c-44.18 0-80 42.98-80 96 0 53.01 35.81 96 80 96 44.17 0 80-42.97 80-96 0-53.02-35.82-96-80-96z'%3E%3C/path%3E%3C/svg%3E");
        }
      }

      &.method-card {
        .icon {
          background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' class='' width='26px' height='100%25'%3E%3Cpath fill='currentColor' d='M527.9 32H48.1C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48.1 48h479.8c26.6 0 48.1-21.5 48.1-48V80c0-26.5-21.5-48-48.1-48zM54.1 80h467.8c3.3 0 6 2.7 6 6v42H48.1V86c0-3.3 2.7-6 6-6zm467.8 352H54.1c-3.3 0-6-2.7-6-6V256h479.8v170c0 3.3-2.7 6-6 6zM192 332v40c0 6.6-5.4 12-12 12h-72c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h72c6.6 0 12 5.4 12 12zm192 0v40c0 6.6-5.4 12-12 12H236c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h136c6.6 0 12 5.4 12 12z'%3E%3C/path%3E%3C/svg%3E");
        }
      }

      &.method-bank_statement {
        .icon {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='svg-icon' style='width: 1.072265625em; height: 1em;vertical-align: middle;fill: currentColor;overflow: hidden;' viewBox='0 0 1098 1024' version='1.1'%3E%3Cpath d='M548.571429 0l548.571429 219.428571 0 73.142857-73.142857 0q0 14.848-11.702857 25.746286t-27.721143 10.825143l-872.009143 0q-16.018286 0-27.721143-10.825143t-11.702857-25.746286l-73.142857 0 0-73.142857zM146.285714 365.714286l146.285714 0 0 438.857143 73.142857 0 0-438.857143 146.285714 0 0 438.857143 73.142857 0 0-438.857143 146.285714 0 0 438.857143 73.142857 0 0-438.857143 146.285714 0 0 438.857143 33.718857 0q16.018286 0 27.721143 10.825143t11.702857 25.746286l0 36.571429-950.857143 0 0-36.571429q0-14.848 11.702857-25.746286t27.721143-10.825143l33.718857 0 0-438.857143zM1057.718857 914.285714q16.018286 0 27.721143 10.825143t11.702857 25.746286l0 73.142857-1097.142857 0 0-73.142857q0-14.848 11.702857-25.746286t27.721143-10.825143l1018.294857 0z'/%3E%3C/svg%3E");
        }
      }
    }
  }
}
.calendar-popover {
  div.popover.show.bs-popover-auto {
    width: 100%;
    max-width: 335px !important;
  }
}

.rdp {
  &.date-range-picker {
    --rdp-accent-color: #11cdef;
  }
}

.text-md {
  font-size: 1rem;
}

.sticky-header {
  position: sticky;
  top: 0;
  background-color: white;
  padding: 10px;
  display: flex;
  z-index: -1;
  justify-content: space-between;
  align-items: center;
  opacity: 0;
  padding-bottom: 16px !important;
  transition: all 0.2s ease;

  div {
    opacity: 0;
  }
}

.sticky-header.show {
  display: block;
  z-index: 999;
  transition: all 0.2s ease;
  opacity: 1;
  height: fit-content;
  overflow: unset;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.07);
  opacity: 1;
  div {
    height: auto;
    opacity: 1;
  }
}

.top-2-5 {
  top: 13px;
}

.margin-top--35 {
  margin-top: -35px;
}

.user-sales-table {
  .card {
    min-height: auto;
  }
  @media (min-width: 768px) {
    .card {
      height: 465px;
      .card-body {
        overflow-y: scroll;
      }
    }
  }
}
.orders-view {
  :is(.card-body):first-child {
    padding: 0;
  }

  .orders-table-card {
    padding-bottom: 0;

    .card-footer {
      padding-bottom: 0 !important;
    }

    .pagination-bar {
      display: none !important;
    }

    .card-body {
      padding-bottom: 0 !important;
    }
  }
}

.discount-dropdown-card {
  box-shadow: unset;
  border: 1px solid $gray-300;
}

.textarea-border-left-0 {
  .ql-editor {
    border-left: unset;
    border-radius: 0;
  }
}

.search-container {
  &.mobile {
    .search-dialog {
      .search-results-container {
        width: 100vw;
        left: -15px;
        border-radius: 0;

        @media (min-width: 768px) {
          width: 450px;
          border-radius: 0 0 20px 20px;
          left: 0;
        }
      }
    }
  }

  .search-btn {
    position: relative;
    font-size: 20px;
    background: none;
    box-shadow: none;
    border: none;

    &:hover,
    &:active {
      background: none;
      box-shadow: none;
      background-color: transparent !important;
      border-color: none !important;
    }
  }

  .search-dialog {
    position: relative;
    width: 100%;
    transition: all 0.15s ease-in-out;

    @media (min-width: 768px) {
      min-width: 500px;
    }

    @media (max-width: 767px) {
      max-width: calc(100% - 30px);
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 15px;
      z-index: 99;
    }

    &.hidden {
      transition: all 0.15s ease-in;
      min-width: 0;
      width: 0;
      * {
        display: none;
      }
    }
    .search-input-container {
      display: flex;
      flex-flow: row-reverse;
      border-radius: 10px;
      height: 44px;
      z-index: 10;
      border-radius: 2rem;
      border: 0 solid;
      transition: background-color 0.1s ease-in;
      transition-delay: 0.15s;
      background-color: hsla(0, 0%, 100%, 0.9);
      border-color: rgba(0, 0, 0, 0.6);

      @media (min-width: 768px) {
        width: 450px;
      }

      &.active {
        background-color: $white;

        &.search-results-visible {
          @media (min-width: 768px) {
            border-bottom: 1px solid rgba(0, 0, 0, 0.15);
            border-radius: 20px 20px 0 0;
          }
        }
      }

      .search-input {
        border: none;
        background-color: transparent;
      }

      .magnifier {
        .input-group-text {
          background-color: transparent;
          border: none;
        }
      }

      .search-close {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 10px;
        z-index: 999;

        .close-icon {
          color: $gray-500;
        }
      }
    }

    .search-results-container {
      width: 100%;
      position: absolute;
      top: 44px;
      left: 0;
      z-index: 1;
      background: $white;
      border-radius: 0 0 20px 20px;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2), 0 0 0 0 rgba(0, 0, 0, 0); /* Adjust the shadow values as needed */
      padding-bottom: 10px;
      .search-results {
        max-height: 500px;
        overflow-y: auto;

        &::-webkit-scrollbar {
          background-color: $white;
          width: 7px;
        }

        &::-webkit-scrollbar-track {
          background-color: $white;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $steel;
          border-radius: 16px;
          border: 2px solid $steel;
        }

        &::-webkit-scrollbar-button {
          display: none;
        }

        .search-result {
          .card {
            box-shadow: none;
          }
          &:first-child {
            margin-top: 10px !important;
          }
          &:last-child {
            .card {
              margin-bottom: 0 !important;
            }
          }
        }
      }
    }
  }
}

.non-collapsible-header {
  &::after {
    display: none;
  }
}

.calculator-modal {
  input[type="text"],
  input[type="number"],
  input[type="email"],
  input[type="password"],
  textarea {
    font-size: 18px !important;
  }

  .text-sm-md {
    font-size: 1rem !important;
  }

  .text-md {
    font-size: 1.2rem !important;
  }
}
.calculator-button {
  box-shadow: 0 2px 4px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.print-scenarios-table-container {
  table {
    td {
      p {
        margin-bottom: 0;
      }
    }
  }
}

.react-bootstrap-table th.sortable {
  cursor: pointer;
}

.box-shadow-medium {
  box-shadow: 0 -3px 3px rgba(0, 0, 0, 0.06);
}

.scrollable-cart {
  max-height: 70vh;
  overflow: scroll;
}

.customer-selector-card-header {
  min-height: 52px !important;
}

.refresh-loader-top {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px 0;
}

.z-index--1 {
  z-index: -1 !important;
}
#ptr-parent {
  overflow: unset !important;
  -webkit-overflow-scrolling: unset !important;

  #ptr-container {
    height: 100vh !important;
    overflow: unset !important;
    -webkit-overflow-scrolling: unset !important;
  }
}

.fixed-top-error {
  position: relative;
  top: 0;
  left: 0;
  z-index: 99999;
  background: transparent;
  width: 100%;
  div {
    border-radius: 0;
  }
}

#show-cart-btn {
  max-width: 600px;
}

.textarea-editor {
  height: 100px;
  width: 100%;
  border: 1px solid #dee2e6;
  z-index: 0;
  position: relative;

  .public-DraftEditorPlaceholder-inner {
    color: #adb5bd;
  }
}

.border-top-light {
  border-top: 1px solid #dee2e6;
}

.text-md-lg {
  font-size: 1.0625rem;
}

#tipsTypeSelector,
#percentageSelector,
#posTerminalSelector {
  font-size: 16px !important;
}

.vat-search-button {
  position: absolute;
  right: 0;
  top: 10px;
  padding: 0;

  &.disabled {
    color: #c5c5c5;
  }
}

.search-cust-container {
  position: relative;
  .search-results {
    position: absolute;
    display: block;
    z-index: 9999;
    background-color: #fff;
    left: 0px;
    top: 5px;
    width: 100%;
    padding: 5px;
    border-radius: 5px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.07);
    max-height: 250px;
    overflow-x: hidden;
    overflow-y: auto;
    .search-result {
      cursor: pointer;
      .text-bold {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
